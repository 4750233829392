<script>
  import { createEventDispatcher } from "svelte";
  import Dialog from "./Dialog.svelte";

  const dispatch = createEventDispatcher();

  //export let pay;

  let mobileMenuOpen = false;
  let billsDialog = false;
  let payDialog = false;

  let amount;
  let day;
  let name;

  function toggleMenu() {
    mobileMenuOpen = !mobileMenuOpen;
    amount = null;
    day = null;
    name = null;
  }
  function closeMenu() {
    mobileMenuOpen = false;
  }
  function addBill() {
    dispatch("addBill", { day: day, name: name, amount: amount });
    billsDialog = false;
  }
  function editPay() {
    console.log("Edit");
  }
</script>

<header class="flex flex-wrap flex-row justify-between md:items-center md:space-x-4 bg-white py-1 px-2 relative">
  <button on:click={toggleMenu} class="block text">
    <span>Swifty Budget</span>
  </button>
  <button on:click={toggleMenu} class="inline-block md:hidden w-8 h-8 bg-gray-200 text-gray-600 p-1">
    <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd" />
    </svg>
  </button>
  <nav class="absolute md:relative top-10 left-0 md:top-0 z-20 md:flex flex-col md:flex-row md:space-x-6 font-semibold w-full md:w-auto bg-white shadow-md rounded-lg md:rounded-none md:shadow-none md:bg-transparent p-6 pt-0 md:p-0" class:flex={mobileMenuOpen} class:hidden={!mobileMenuOpen}>
    <a
      on:click={() => {
        billsDialog = true;
      }}
      href="/#"
      class="block py-1 text-indigo-600 hover:text-red-600">
      Add Bills
    </a>
    <a on:click={editPay} class="block py-1 text-indigo-600 hover:text-gray-600" href="/#">Edit Pay</a>
  </nav>
</header>

<Dialog showDialog={billsDialog}>
  <span slot="title">
    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Add a new Bill</h3>
  </span>
  <span slot="body">
    <div class="form-group">
      <input bind:value={name} id="name" placeholder=" " class="text-field" autocomplete="off" />
      <label for="name" class="label">Name</label>
    </div>
    <div class="form-group">
      <input class="text-field" bind:value={amount} placeholder=" " id="amount" />
      <label for="amount" class="label">Amount</label>
    </div>
    <div class="form-group">
      <input label="Day of the Month" class="text-field" type="number" bind:value={day} placeholder=" " id="day" min="1" max="28" />
      <label for="day" class="label">Day of the Month</label>
    </div>
  </span>
  <span slot="buttons" class="flex flex-row-reverse bg-gray-200 p-4">
    <button on:click={addBill} type="button" class="primary-btn">Save</button>
    <button on:click={() => (billsDialog = !billsDialog)} type="button" class="secondary-btn">Cancel</button>
  </span>
</Dialog>
