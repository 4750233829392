import { isFuture, subDays, addMonths, compareAsc, parse } from "date-fns";
import type { Bill } from "./interfaces/bill";
import type { BillWithDate, Period } from "./interfaces/period";

export default class Service {
  formatCurrency(amount) {
    const currencyFormatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return currencyFormatter.format(amount);
  }

  formatDay(date) {
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    return `${day}`;
  }

  formatDate(date) {
    let month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
    let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
    return `${month} ${day}`;
  }

  dateBetween(date: Date, beginDate: Date, endDate: Date): boolean {
    const between = date >= beginDate && date <= endDate;
    //console.log(`${this.formatDate(date)} is${between ? "" : " not"} between ${this.formatDate(beginDate)} and ${this.formatDate(endDate)}`);
    return between;
  }

  getDateBetween(day: number, beginDate: Date, endDate: Date): Date {
    if (day >= beginDate.getDate()) {
      return new Date(beginDate.getFullYear(), beginDate.getMonth(), day);
    }
    return new Date(endDate.getFullYear(), endDate.getMonth(), day);
  }

  saveBankBalance(bankBalance) {
    localStorage.setItem("/data/bankBalance", bankBalance);
  }

  getBankBalance() {
    let bankBalanceStorage = localStorage.getItem("/data/bankBalance");
    let bankBalance = parseFloat(bankBalanceStorage || "0");
    bankBalance = bankBalance || 0;
    return bankBalance;
  }

  savePeriods(periods) {
    localStorage.setItem("/data/periods", JSON.stringify(periods));
  }

  getBills(): Promise<Bill[]> {
    let bills = localStorage.getItem("/data/bills");
    bills = bills || "[]";
    return JSON.parse(bills);
  }

  async deleteBill(bills, payDates, bill) {
    bills = bills.filter((b) => b.amount !== bill.amount && b.name !== bill.name && b.day !== bill.day);
    this.saveBills(bills);
    const generatedPeriods = this.getPeriods(payDates, bills);
    await this.injectSavedPeriods(generatedPeriods);
    return generatedPeriods;
  }

  async getPayDates() {
    return new Promise((resolve, reject) => {
      resolve([
        { amount: 100, begin: new Date("7-7-2021"), repeat: "monthly", weekend: false },
        { amount: 100, begin: new Date("7-22-2021"), repeat: "monthly", weekend: false },
      ]);
    });
  }

  getPeriods(payDates: Date[], bills: Bill[]) {
    const periods = [];

    payDates.forEach((date, index) => {
      let period = {} as Period;
      const nextDate = payDates[index + 1];
      if (!!nextDate) {
        period.startDate = date;
        period.endDate = subDays(nextDate, 1);
        period.bills = new Array<BillWithDate>();
        bills.forEach((bill, billIndex) => {
          const billDate = this.getDateBetween(bill.day, period.startDate, period.endDate);
          if (this.dateBetween(billDate, period.startDate, period.endDate)) {
            period.bills.push({ day: bill.day, amount: bill.amount, name: bill.name, date: billDate });
          }
        });
        period.bills.sort((b1, b2) => b1.date.getTime() - b2.date.getTime());
        periods.push(period);
      }
    });
    return periods;
  }

  async injectSavedPeriods(generatedPeriods) {
    let savedPeriodsText = localStorage.getItem("/data/periods");
    let savedPeriods = JSON.parse(savedPeriodsText);
    if (savedPeriods) {
      savedPeriods.forEach((sp) => {
        let matchedPeriod = generatedPeriods.find((gp) => gp.startDate.getTime() == new Date(sp.startDate).getTime() && gp.endDate.getTime() == new Date(sp.endDate).getTime());
        if (matchedPeriod) {
          sp.bills.forEach((spb) => {
            let matchedBill = matchedPeriod.bills.find((b) => b.name == spb.name && b.day == spb.day);
            if (matchedBill) {
              matchedBill.amount = spb.amount;
            }
          });
        }
      });
    }
  }

  saveBills(bills: Bill[]) {
    localStorage.setItem("/data/bills", JSON.stringify(bills));
  }

  addBill(periods: Period[], bills: Bill[], bill: Bill) {
    bills = [...bills, bill];
    this.saveBills(bills);
    periods.forEach((period) => {
      const billDate = this.getDateBetween(bill.day, period.startDate, period.endDate);
      if (this.dateBetween(billDate, period.startDate, period.endDate)) {
        period.bills = [...period.bills, { day: bill.day, amount: bill.amount, name: bill.name, date: billDate }];
      }
      period.bills = period.bills.sort((b1, b2) => b1.date.getTime() - b2.date.getTime());
    });
    this.savePeriods(periods);
    return periods;
  }

  getFuturePayDates(payDates, currentDate) {
    payDates.sort((a, b) => a.begin - b.begin);
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const futurePayDates = [];
    payDates.forEach((payDate) => {
      if (payDate.repeat == "monthly") {
        const day = payDate.begin.getDate();
        for (let i = currentMonth; i < 13; i++) {
          const nextDate = new Date(`${i}-${day}-${currentYear}`);
          if (nextDate > currentDate) {
            futurePayDates.push(nextDate);
          }
        }
        for (let i = 1; i < currentMonth; i++) {
          const nextDate = new Date(`${i}-${day}-${currentYear + 1}`);
          if (nextDate > currentDate) {
            futurePayDates.push(nextDate);
          }
        }
      }
    });

    futurePayDates.sort((a, b) => a - b);
    const firstPayDate = futurePayDates[0];

    if (isFuture(firstPayDate)) {
      const monthPayDates = futurePayDates.filter((pd) => pd.getMonth() == firstPayDate.getMonth());
      const lastPayDateOfMonth = monthPayDates[monthPayDates.length - 1];
      const year = lastPayDateOfMonth.getMonth() == 0 ? lastPayDateOfMonth.getFullYear() - 1 : lastPayDateOfMonth.getFullYear();
      const month = (lastPayDateOfMonth.getMonth() == 11 ? 0 : lastPayDateOfMonth.getMonth() - 1) + 1;
      const day = lastPayDateOfMonth.getDate();
      futurePayDates.unshift(new Date(`${month}-${day}-${year}`));
    }
    return futurePayDates;
  }
}
