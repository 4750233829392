<script>
  import Service from "./service";
  import { createEventDispatcher } from "svelte";
  import BillDate from "./BillDate.svelte";

  const dispatch = createEventDispatcher();
  const service = new Service();

  export let startDate;
  export let endDate;
  export let bankBalance;
  export let start;
  export let end;
  export let bills;
  export let index;
  export let pay;
  export let billTotal;

  $: first = index == 0;
  $: even = index % 2;

  const date = (val) => service.formatDate(val);
  const day = (val) => service.formatDay(val);
  const money = (val) => service.formatCurrency(val);

  let timer;
  const debounce = (dispatching, payload) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(dispatching, payload);
    }, 750);
  };
</script>

<style>
  #bankBalance {
    width: 100px;
  }
</style>

<div class="col-span-2 self-end font-bold">{date(startDate)} through {date(endDate)}</div>
{#if first}
  <div class="justify-self-end form-group">
    <input bind:value={bankBalance} class="text-field text-right" type="number" on:keyup={({ target: { value } }) => debounce('saveBankBalance', { value })} id="bankBalance" />
    <label for="bankBalance" class="label">Bank Balance</label>
  </div>
{:else}
  <div />
{/if}

{#each bills as bill, index}
  <div>
    <BillDate {...bill} even={index % 2 == 1} on:deleteBill on:saveBill>{day(bill.date)}</BillDate>
  </div>
  <div class:bg-blue-50={index % 2} class="justify-self-stretch place-self-stretch p-2">{bill.name}</div>
  <div>
    <input bind:value={bill.amount} on:keyup={({ target: { value } }) => debounce('savePeriods')} class="bg-{index % 2 == 0 ? 'blue' : 'gray'}-50 w-full text-right border px-1 focus:bg-white py-2" type="number" />
  </div>
{/each}
<div class="col-start-2 bg-red-300">Bill Total</div>
<div class="bg-red-300 text-right">{money(billTotal)}</div>
<div class="col-start-2">Ending Amount</div>
<div class="text-right">{money(end)}</div>
<div class="col-start-2">Pay</div>
<div class="text-right">{money(pay)}</div>
<div class="col-start-2 startingAmountText">Starting Amount</div>
<div class="text-right" id="startingAmount">{money(start)}</div>
