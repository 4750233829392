<script lang="ts">
  import PayPeriod from "./PayPeriod.svelte";
  import Header from "./Header.svelte";
  import Service from "./service";

  const service = new Service();
  let pay = 3609.31;
  let bankBalance = 1000;

  let periods = [];
  let bills = [];
  let payDates = [];

  $: {
    periods.forEach((period, index) => {
      period.billTotal = period.bills.map((bill) => Number(bill.amount)).reduce((acc, val) => acc + val, 0);
      period.end = (index == 0 ? Number(bankBalance) || 0 : periods[index - 1].start) + period.billTotal;
      period.start = index == 0 ? (Number(bankBalance) || 0) + period.billTotal + pay : period.end + pay;
    });
    periods = [...periods];
  }

  let periodsPromise = (async () => {
    bankBalance = await service.getBankBalance();
    const savedPayDates = await service.getPayDates();
    bills = await service.getBills();
    payDates = service.getFuturePayDates(savedPayDates, new Date()).slice(0, 5);
    const generatedPeriods = service.getPeriods(payDates, bills);
    await service.injectSavedPeriods(generatedPeriods);
    periods = generatedPeriods;
  })();

  function handleNewBill(event) {
    periods = service.addBill(periods, bills, event.detail);
  }

  function handleSavePeriods() {
    service.savePeriods(periods);
  }

  function handleNewBankBalance(event) {
    service.saveBankBalance(event.detail.value);
  }

  function handleDeleteBill(event) {
    (async () => {
      console.log("handleDeleteBill");
      const bill = event.detail;
      periods = await service.deleteBill(bills, payDates, bill);
    })();
  }
  function handleSaveBill() {}
</script>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
  .label {
    @apply absolute left-2 px-1 top-1 py-1 cursor-text;
    transition: top 100ms ease-in, font-size 100ms ease-in;
  }
  .text-field:focus ~ .label,
  .text-field:not(:placeholder-shown).text-field:not(:focus) ~ .label {
    @apply text-xs bg-white py-0 mt-1;
    top: -13px;
  }
  .form-group {
    @apply flex flex-col my-4 relative;
  }
  .text-field {
    @apply border py-2 px-3 border-gray-300 hover:border-gray-500 focus:border-blue-400;
  }
  .btn {
    @apply rounded-md border border-transparent shadow-sm p-2 text-xs
    sm:text-sm sm:px-4 sm:mx-2;
  }
  .primary-btn {
    @apply btn bg-red-600 text-white 
    hover:bg-red-700 
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
  }
  button.secondary-btn {
    @apply btn border-gray-300 bg-white text-gray-700 mr-2
    hover:bg-gray-50 
    focus:ring-indigo-500;
  }
  .grid.bill-grid {
    grid-template-columns: 50px auto 100px;
  }
</style>

<main>
  <Header on:addBill={handleNewBill} />
  <div id="budget">
    <div class="container mx-auto p-3 m-0">
      <div class="grid gap-1 grid-cols-3 bill-grid">
        {#await periodsPromise then ps}
          {#each periods as period, index}
            <PayPeriod {...period} bind:pay {index} bind:bankBalance bind:bills={period.bills} on:savePeriods={handleSavePeriods} on:saveBankBalance={handleNewBankBalance} on:saveBill={handleSaveBill} on:deleteBill={handleDeleteBill} />
          {/each}
        {/await}
      </div>
    </div>
  </div>
</main>
