<script>
  import Dialog from "./Dialog.svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  let editBillDialog = false;
  export let name;
  export let amount;
  export let day;
  export let even;
  export let date;

  function saveBill() {
    dispatch("saveBill", { name, amount, day, date });
    editBillDialog = false;
  }
  function deleteBill() {
    console.log("dispatch delete bill");
    dispatch("deleteBill", { name, amount, day, date });
    editBillDialog = false;
  }
</script>

<button
  class="bg-blue-{even ? '500' : '300'} hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
  on:click={() => {
    editBillDialog = true;
  }}
>
  <slot />
</button>

<Dialog showDialog={editBillDialog}>
  <span slot="title">
    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">Edit Bill</h3>
  </span>
  <span slot="body">
    <div class="form-group">
      <input bind:value={name} id="name" placeholder=" " class="text-field" autocomplete="off" />
      <label for="name" class="label">Name</label>
    </div>
    <div class="form-group">
      <input class="text-field" bind:value={amount} placeholder=" " id="amount" />
      <label for="amount" class="label">Amount</label>
    </div>
    <div class="form-group">
      <input label="Day of the Month" class="text-field" type="number" bind:value={day} placeholder=" " id="day" min="1" max="28" />
      <label for="day" class="label">Day of the Month</label>
    </div>
  </span>
  <span slot="buttons" class="flex bg-gray-200 p-4">
    <div class="w-1/2 flex">
      <button on:click={deleteBill} type="button" class="primary-btn">Delete</button>
    </div>
    <div class="w-1/2 flex flex-row-reverse">
      <button on:click={saveBill} type="button" class="primary-btn">Save</button>
      <button on:click={() => (editBillDialog = !editBillDialog)} type="button" class="secondary-btn">Cancel</button>
    </div>
  </span>
</Dialog>
